import React from 'react';
import '../Login/Login.css';
import banner from "../../assets/images/register_banner.png";
import { Link } from 'react-router-dom';


function RegisterBanner() {
    return(
      <>
    <div class="d-flex flex-lg-row-fluid order-2 order-lg-1 left-image">
        <div class="pb-0 pb-lg-10 p-10 w-100"> 
        <Link to="https://slidesmap.com" >
                                                            <h1 class="logo-text">
                                                                Slidesmap
                                                            </h1>
                                                        </Link> 
            <img class="theme-dark-show mx-auto mw-100 w-100" src={banner} alt="banner" />                 
        </div>
    </div>
    </>
  )
}

export default RegisterBanner;
