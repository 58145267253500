import React, { useState, useEffect } from 'react';
import "./profile.css"
import { getUserProfile } from '../../services/user';
import { Link } from 'react-router-dom';

function ViewProfile() {
    const [userDetails, setUserDetails] = useState();
    const getUserProfileDetails = async () => {
        try {
            const response = await getUserProfile();
            setUserDetails(response.data.data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getUserProfileDetails()
    }, [])
    return (
        <>
            <div className="d-flex flex-column flex-column-fluid">
                <div id="kt_app_toolbar" class=" py-3 py-lg-6 ">
                    <div id="kt_app_toolbar_container" className="app-container  container-xxl d-flex flex-stack ">
                        <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                            <h1 class="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                                View Profile
                            </h1>

                            <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">

                                <li class="breadcrumb-item text-muted">
                                    <a href="/" class="text-muted text-hover-primary">
                                        Home                            </a>
                                </li>

                                <li class="breadcrumb-item">
                                    <span class="bullet bg-gray-500 w-5px h-2px"></span>
                                </li>

                                <li class="breadcrumb-item text-muted">
                                    Profile                                           </li>
                            </ul>

                        </div>
                    </div>
                    <div id="kt_app_content" className="app-content  flex-column-fluid ">
                        <div id="kt_app_content_container" className="app-container  container-xxl ">
                            <div class="card mt-5 mb-5 mb-xl-10" id="kt_profile_details_view">
                                <div class="card-header cursor-pointer card-header-styles">
                                    <div class="card-title m-0">
                                        <h3 class="fw-bold m-0">Profile Details</h3>
                                    </div>
                                    <div className='py-5'>
                                        <Link to="/changePassword" class="btn btn-sm btn-primary align-self-center me-3">Change Password</Link>
                                        <Link to="/editProfile" class="btn btn-sm btn-primary align-self-center">Edit Profile</Link>
                                    </div>
                                </div>
                                <div class="card-body p-9">

                                    <div class="row mb-7">

                                        <label class="col-lg-4 fw-semibold text-muted">Full Name</label>
                                        <div class="col-lg-8">
                                            <span class="fw-bold fs-6 text-gray-800">{userDetails?.fullname}</span>
                                        </div>

                                    </div>
                                    <div class="row mb-7">

                                        <label class="col-lg-4 fw-semibold text-muted">Email</label>

                                        <div class="col-lg-8">
                                            <a href="#" class="fw-semibold fs-6 text-gray-800 text-hover-primary">{userDetails?.email}</a>
                                        </div>
                                    </div>

                                    <div class="row mb-7">

                                        <label class="col-lg-4 fw-semibold text-muted">Mobile No</label>

                                        <div class="col-lg-8">
                                            <a href="#" class="fw-semibold fs-6 text-gray-800 text-hover-primary">{userDetails?.mobileno}</a>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default ViewProfile;
