import React, { useState, useEffect } from 'react';
import { deleteEvent, getList } from '../../../services/presentation';
import { Link } from 'react-router-dom';
import WarningModal from './WarningModal';
import ShareLinkModal from './ShareLinkModal';
import EmbedModal from './EmbedModal';
import CopyMessageModal from './CopyMessageModal';


function List() {
  const [presentationData, setPresentationData] = useState([]);
  const status = { "P": "Published", "D": "Draft" }
  const [eventId, setEventId] = useState();
  const [showWarning, setShowWarning] = useState(false);
  const [alertMsg, setAlertMsg] = useState(false);
  const [showAlert, setShowAlert] = useState(false)
  const [shareableLink, setShareableLink] = useState('');
  const [showShareable, setShowShareable] = useState(false);
  const [showEmbedModal, setShowEmbedModal] = useState(false);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [showEmbedCopyModal, setShowEmbedCopyModal] = useState(false);
  const [embedcode, setEmbedcode] = useState('');

  const getPresentations = async () => {
    try {
        const response = await getList();
        setPresentationData(response.data.data);
    } catch (error) {
      setPresentationData([]);
        console.error(error);
    }
};

useEffect(() => {
    getPresentations();
}, []);

const deleteRecord = async (id) => {
  setShowWarning(true);
  setEventId(id);
}

const handleWarningModal = () => {
  setShowWarning(false);
}

const removeEvent = async () => {
setShowWarning(false);
setAlertMsg(true);
try {
  const response = await deleteEvent(eventId);
   if (response.data.status) {
    getPresentations();
    }else{
      //show warning alert
      setShowAlert(true);
    }
  setAlertMsg(false);

} catch (error) {
  setAlertMsg(false);
}
};

 const generateCodes = async (event_code) => {
        const currentUrl = window.location.origin + window.location.pathname; // Get current URL
        const link = currentUrl+'/view/'+event_code; 

        setShareableLink(link);
        setShowShareable(true); 
    };

    const handleShareModal = () => {
      setShowShareable(false);
    }

    
    const handleEmbed = () => {
      setShowShareable(false);
      setShowEmbedModal(true);
  }

  const handleCopy = () => {
    setShowShareable(false);
    setShowCopyModal(true);
    navigator.clipboard.writeText(shareableLink).then(() => {
      console.log('Link copied to clipboard!');
  }).catch(err => {
    console.log('Failed to copy the link!');
  });
}

const closeCopy = () => {
  setShowCopyModal(false);
}

  const handleModal = () => {
      setShowEmbedModal(false);
  }

  const handleEmbedCopy = () => {
    setShowEmbedModal(false);
    setShowEmbedCopyModal(true);
    const code = '<iframe src="'+shareableLink+'" width="600" height="400" frameborder="0" allowfullscreen></iframe>';
    setEmbedcode(code);
    navigator.clipboard.writeText(embedcode).then(() => {
      console.log('Link copied to clipboard!');
  }).catch(err => {
    console.log('Failed to copy the link!');
  });
}


const closeEmbedCopy = () => {
  setShowEmbedCopyModal(false);
}

  return (
    <>
      <div className="d-flex flex-column flex-column-fluid">
        <div id="kt_app_toolbar" className="app-toolbar  py-3 py-lg-6">
          <div id="kt_app_toolbar_container" className="app-container container-xxl text-center">
            <div className="page-title me-3 ">
              <h1 className="page-heading text-gray-900 fw-bold fs-3 my-0">
                Welcome to Presentation
              </h1>
            </div>
          </div>
        </div>
        <div id="kt_app_content" className="app-content  flex-column-fluid ">
          <div id="kt_app_content_container" className="app-container  container-xxl ">
            <div className="card mb-5 mb-xl-8">
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold fs-3 mb-1">Presentations</span>
                </h3>
                <div className="card-toolbar">
                  <Link to="/presentation/configuration/" className="btn btn-sm btn-light-primary">
                    <i className="fa fa-plus"></i>New Presentation
                  </Link>
                </div>
              </div>
              <div className="card-body py-3">
                <div className="table-responsive">
                  <table className="table align-middle gs-0 gy-4">
                    <thead>
                      <tr className="fw-bold text-muted bg-light">
                        <th className="ps-4 min-w-325px rounded-start">Name</th>
                        <th className="ps-4 min-w-325px rounded-start">Layout Type</th>
                        <th className="min-w-150px">Status</th>
                        <th className="pe-4 min-w-200px text-end rounded-end">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                    {presentationData?.map((item, i) => {
                    return (
                      <tr key={item.eventId}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span className="text-gray-900 fw-bold mb-1 fs-6">{item.eventName}</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              {item.layoutType}
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className="badge badge-light-success fs-7 fw-bold">{status[item.status]}</span>
                        </td>
                        <td className="text-end">
                          {item.status === 'P' ?
                          <button onClick={() => generateCodes(item.eventCode)} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                            <i className="fa fa-share"></i>                                </button>
                            :""}
                          <Link to={"/presentation/preview/"+item.eventCode} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                            <i className="fa fa-eye"></i>                                </Link>

                            <Link to={"/presentation/configuration/"+item.eventCode} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                            <i className="fa fa-pencil"></i>                                </Link>

                          <button onClick={() => deleteRecord(item.eventId)} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <i className="fa fa-trash"></i>  
                                                          </button>
                            {item.status === 'P' &&
                             <>{item.allowRegister === 'Y' &&  
                            <Link to={"/presentation/registrations/"+item.eventCode} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                            <i className="fa fa-registered"></i>                                </Link>
                             }</>}
                        </td>
                      </tr>
                    );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
           
            {showShareable && <ShareLinkModal show={showShareable} shareablelink={shareableLink} onHide={handleShareModal} handleEmbed={handleEmbed} handleCopy={handleCopy} />}
            {showEmbedModal && <EmbedModal show={showEmbedModal} shareablelink={shareableLink} onHide={handleModal} handleEmbedCopy={handleEmbedCopy} />}
            {showWarning && <WarningModal eventId={eventId} show={showWarning} cancel={removeEvent} onHide={handleWarningModal} message={"Do you really want to delete?"} />}
            {showCopyModal && <CopyMessageModal show={showCopyModal} onHide={closeCopy} message={"Link copied to clipboard!"}/>}
            {showEmbedCopyModal && <CopyMessageModal show={showEmbedCopyModal} onHide={closeEmbedCopy} message={"Link copied to clipboard!"}/>}

          </div>
        </div>

      </div>

    </>
  )
}

export default List;