import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import '../Login/Login.css'
import axios from 'axios';
import { API_BASE_URL, VERIFY_EMAIL } from '../../Constants';
import loading from "../../assets/images/loader_grey.gif";
import SweetAlert from "react-bootstrap-sweetalert";

function VerifyEmail() {
    const params = useParams();
    const code = params.code;
     const [message, setMessage] = useState("");
 const [alertMsg, setAlertMsg] = useState(false);

    useEffect(()=>{
        setAlertMsg(true);
        verifyEmail();
    },[]);

    const verifyEmail = async =>{
        axios.get(VERIFY_EMAIL+code)
  .then(response => {
    setAlertMsg(false);
      if(response.data.data){
          setMessage("Thanks for verifying your email.");
      }else{
        setMessage("Verification Link is not valid.<br />Can you check your email and verify the link")
      }
  })
  .catch(error => {
    setAlertMsg(false);
    setMessage("Verification Link is not valid.<br />Can you check your email and verify the link")
  });
    }
    return (
        <>
            <div id="kt_app_toolbar" class="container-xxl">
                <div className="row mt-8">
                    <div className="col-md-12 text-center">
                    <div className="mb-30">
              <p className="logo-text">Slidesmap</p>
            </div>
            {alertMsg ?
                                    <SweetAlert
                                        title=""
                                        showConfirm={false}
                                        style={{ width: "200px", height: "100px" }}
                                    > <img src={loading} width="50px" height="50px" />
                                    </SweetAlert>
                                    : <></>}
            <div className="mt-100">
                        <p className="fs-35"><div dangerouslySetInnerHTML={{ __html: message }} /></p>
                        <div className="mt-100">
                            <Link to="/login" className="btn btn-primary">
                                Login
                            </Link>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default VerifyEmail;
