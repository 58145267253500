import React, { useState, useEffect } from 'react';
import { getRegistrationList } from '../../../services/presentation';
import { useParams } from 'react-router-dom';

function Registrations() {
  const params = useParams();
  const code = params.eventCode;

  const [registrationData, setRegistrationData] = useState([]);

  const getRegistrations = async () => {
    try {
        const response = await getRegistrationList(code);
        setRegistrationData(response.data.data);
    } catch (error) {
      setRegistrationData([]);
        console.error(error);
    }
};

useEffect(() => {
  getRegistrations();
}, []);


  return (
    <>
      <div className="d-flex flex-column flex-column-fluid">
        <div id="kt_app_toolbar" className="app-toolbar  py-3 py-lg-6">
          <div id="kt_app_toolbar_container" className="app-container container-xxl text-center">
            <div className="page-title me-3 ">
            
            </div>
          </div>
        </div>
        <div id="kt_app_content" className="app-content  flex-column-fluid ">
          <div id="kt_app_content_container" className="app-container  container-xxl ">
            <div className="card mb-5 mb-xl-8">
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold fs-3 mb-1">Registrations</span>
                </h3>
              </div>
              <div className="card-body py-3">
                <div className="table-responsive">
                  <table className="table align-middle gs-0 gy-4">
                    <thead>
                      <tr className="fw-bold text-muted bg-light">
                        <th className="ps-4 min-w-325px rounded-start">First Name</th>
                        <th className="min-w-325px rounded-start">Last Name</th>
                        <th className="min-w-150px">Email</th>
                      </tr>
                    </thead>
                    <tbody>
                    {registrationData?.map((item, i) => {
                    return (
                      <tr key={item.registrationId}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span className="ps-4 text-gray-900 fw-bold mb-1 fs-6">{item.firstName}</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                            <span className="text-gray-900 fw-bold mb-1 fs-6">{item.lastName}</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className="fs-7 fw-bold"> {item.email}</span>
                        </td>
                      </tr>
                    );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </>
  )
}

export default Registrations;