import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from "axios";
import { API_BASE_URL, GET_PRESENTATION_DETAILS } from '../../Constants.js';
import Footer from './Footer.js';
import 'swiper/swiper-bundle.css';
import ViewOne from './ViewOne.js';
import ViewTwo from './ViewTwo.js';
import ViewThree from './ViewThree.js';
import ViewFour from './ViewFour.js';
import ViewDraft from './ViewDraft.js';
import EventRegister from './EventRegister.js';
import Cookies from 'js-cookie';

function View() {
    const params = useParams();
    const code = params.eventCode;

    const [eventCode, setEventCode] = useState("");
    const [eventName, setEventName] = useState("");
    const [layoutType, setLayoutType] = useState("");
    const [status, setStatus] = useState("");
    const [isRegister, setIsRegister] = useState("");
    const [registerFlag, setRegisterFlag] = useState("N");

    const [cookieValue, setCookieValue] = useState("");

  // Function to handle removing a cookie
  const removeCookie = () => {
    Cookies.remove('myCookie');
    setCookieValue("");
  };

    useEffect(() => {
        if (code) {
            setEventCode(code);
           
        }
    });

    useEffect(() => {
        if (code) {
        /*Get cookie value (if exists)
        const cookie = Cookies.get('myCookie');
        if (cookie) {
          setCookieValue(cookie);
        } else {
          setCookieValue("");
        }*/
        getDetails(code);
        }
    }, []);

    const getDetails = async (event_code) => {
        try {
            const response = await axios.get(API_BASE_URL + GET_PRESENTATION_DETAILS + event_code);
            if (response.data) {
                setLayoutType(response.data.data.layoutType);
                setEventName(response.data.data.eventName);
                setStatus(response.data.data.status);
                setIsRegister(response.data.data.allowRegister);
                if(response.data.data.allowRegister === "Y"){
                    const cookie = Cookies.get('myCookie');
                    if(code === cookie){
                        setRegisterFlag("N");
                    }else{
                        setRegisterFlag("Y");
                    }
                }
            }
        } catch (error) {
        }
    };



    return (
        <>
        {status === 'P' &&
        <>
          {registerFlag === "Y" ? <EventRegister eventCode={eventCode} /> :
            <div className="d-flex flex-column flex-root app-root app" id="kt_app_root">
                <div className="app-page  flex-column flex-column-fluid " id="kt_app_page">
                    <div className="app-wrapper  flex-column flex-row-fluid " id="kt_app_wrapper">
                        <div className="d-flex flex-column flex-column-fluid content">
                            <div id="kt_app_toolbar" className="app-toolbar py-lg-6">
                                <div id="kt_app_toolbar_container" className="app-container container-xxl mt-8">
                                 <h1 className="my-0 event-title">
                                   {eventName}
                                    </h1>
                                </div>
                            </div>
                            <div id="kt_app_content" className="app-content  flex-column-fluid ">
                            <div id="kt_app_content_container" className="app-container  container-xxl ">
                            {layoutType === 'ONE' && <ViewOne eventCode={eventCode} />}
                            {layoutType === 'TWO' && <ViewTwo eventCode={eventCode} />}
                            {layoutType === 'THREE' && <ViewThree eventCode={eventCode} />}
                            {layoutType === 'FOUR' && <ViewFour eventCode={eventCode} />}
                            </div>
                            </div>

                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
         }
        </> 
        }
         {status === 'D' && <ViewDraft />}
        </>
    );
}

export default View;