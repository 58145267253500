import React, { useState, useEffect, useRef } from 'react';
import { saveRegister } from '../../services/presentation';
import Cookies from 'js-cookie';
import SweetAlert from "react-bootstrap-sweetalert";
import loading from "../../assets/images/loader_grey.gif";
import { Link } from 'react-router-dom';
import RegisterBanner from './RegisterBanner';

function EventRegister(props) {
    const code = props.eventCode;

    const [eventCode, setEventCode] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [alertMsg, setAlertMsg] = useState(false);
    const [errFirstName, setErrFirstName] = useState(false);
    const [errLastName, setErrLastName] = useState(false);
    const [errEmail, setErrEmail] = useState(false);
    const [error, setError] = useState('');

    var firstnameInp, lastnameInp, emailInp;

    useEffect(() => {
        firstnameInp = document.getElementById("firstname-input");
        lastnameInp = document.getElementById("lastname-input");
        emailInp = document.getElementById("email-input");
    });

 useEffect(() => {
        if (code) {
            setEventCode(code);
        }
    });

    const validateFields = () => {
        let pass = true;
        if (firstName.trim() == "") {
            setErrFirstName(true);
            firstnameInp.classList.add("error-input-border");
            pass = false;
        }
        if (lastName == "") {
            setErrLastName(true);
            lastnameInp.classList.add("error-input-border");
            pass = false;
        }
        if (email == "") {
            setErrEmail(true);
            emailInp.classList.add("error-input-border");
            pass = false;
        }
        return pass;
    }

    const onChangeEmail = (val) => {
        if (errEmail == true) {
            emailInp.classList.remove("error-input-border");
            setErrEmail(false);
        }
        setEmail(val);

        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (!emailRegex.test(val)) {
            setError('Please enter a valid email address');
        } else {
            setError('');
        }
    }
    const onChangeFirstName = (val) => {
        if (errFirstName == true) {
            firstnameInp.classList.remove("error-input-border");
            setErrFirstName(false);
        }
        setFirstName(val);
    }
    const onChangeLastName = (val) => {
        if (errLastName == true) {
            lastnameInp.classList.remove("error-input-border");
            setErrLastName(false);
        }
        setLastName(val);
    }


    const handleSubmit = async e => {
        e.preventDefault();
        if (!validateFields()) {
            return;
        } else {
            setAlertMsg(true);
            try {
                const response = await saveRegister({
                    firstName,
                    lastName,
                    email,
                    eventCode
                });
                if (response.status) {
                    setAlertMsg(false);
                    setCookie();
                    window.location.href = '/presentation/view/' + eventCode;
                }
            } catch (error) {
                setAlertMsg(false);
            }
        }

    }
  // Function to handle setting a cookie
  const setCookie = () => {
    Cookies.set('myCookie', eventCode, { expires: 3 }); // Expires in 3 days
  };

    return (
        <>
           <div className="d-flex flex-column flex-root login-bg">
                <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                <RegisterBanner />
                    <div class="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 order-1 order-lg-2 right-text">
                        <div class="bg-body d-flex flex-column flex-center rounded-4 w-md-600px p-10">
                            <div class="align-items-stretch w-md-400px">
        {alertMsg ?
                                <SweetAlert
                                    title=""
                                    timeout={10000}
                                    showConfirm={false}
                                    style={{ width: "200px", height: "100px" }}
                                > <img src={loading} width="50px" height="50px" />
                                </SweetAlert>
                                : <></>}

            <form class="form w-100" id="kt_sign_in_form" onSubmit={handleSubmit}>
                 <div class="text-center mb-11">
                     
                <h1>Register Form</h1>
                   
                                                        </div>
                <div class="fv-row mb-8">
                    <input type="text" value={firstName} id="firstname-input"
                        onChange={(e) => onChangeFirstName(e.target.value)}
                        placeholder="First Name" name="firstName" autocomplete="off" class="form-control bg-transparent" />
                    <span className="input-pre-text"><small>&ensp;</small></span>
                    {errFirstName == true ? <span className="input-error-text ml-1rem" ><small>Please Enter Firstname.</small></span> : null}
                </div>

                <div class="fv-row mb-8">
                    <input type="text" value={lastName} id="lastname-input"
                        onChange={(e) => onChangeLastName(e.target.value)}
                        placeholder="Last Name" name="lastName" autocomplete="off" class="form-control bg-transparent" />
                    <span className="input-pre-text"><small>&ensp;</small></span>
                    {errLastName == true ? <span className="input-error-text ml-1rem" ><small>Please Enter Lastname.</small></span> : null}
                </div>

                <div class="fv-row mb-8">
                    <input type="email" value={email} id="email-input"
                        onChange={(e) => onChangeEmail(e.target.value)}
                        placeholder="Email" name="email" autocomplete="off" class="form-control bg-transparent" />
                    <span className="input-pre-text"><small>&ensp;</small></span>
                    {errEmail == true ? <span className="input-error-text ml-1rem" ><small>Please Enter Email.</small></span> : null}
                    {error && <span className="input-error-text ml-1rem" ><small>{error}</small></span>}
                </div>


                <div class="d-grid mb-10">
                    <button type="submit" id="kt_sign_in_submit" class="btn btn-primary">

                        <span class="indicator-label">
                            Submit</span>

                    </button>
                </div>

            </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EventRegister;