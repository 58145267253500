import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import '../Login/Login.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faL, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { resendEmail, verifyEmailCode } from '../../services/user';

function VerifyModal(props) {
    const [code, setCode] = useState("");
    const [message, setMessage] = useState("");
    const [errCode, setErrCode] = useState(false);
    const [errMessage, setErrMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);

    var codeInp;

    useEffect(() => {
        codeInp = document.getElementById("code-input");
    });

    const validateFields = () => {
        let pass = true;
        if (code.trim() == "") {
            setErrCode(true);
            codeInp.classList.add("error-input-border");
            pass = false;
        }
        return pass;
    }
    const onChangeCode = (val) => {
        if (errCode == true) {
            codeInp.classList.remove("error-input-border");
            setErrCode(false);
        }
        if (/^\d{0,6}$/.test(val)) {
          setCode(val);
        }
    }
    const handleSubmit = async e => {
        e.preventDefault();
        if (!validateFields()) {
            return;
        }
        try {          
            setMessage("");
            const response = await verifyEmailCode(code);
            if (response.status) {
                if (response.data.data) {
                    setErrMessage(false);
                setSuccessMessage(true);
                setMessage("Thanks for verifying your email.");
                sessionStorage.setItem("statusFlag", 2);
                sessionStorage.setItem("verifiedemail", true);
                window.location.href = '/dashboard';
            } else {
                setSuccessMessage(false);
                setErrMessage(true);
                setMessage("Verification Code is not valid.")
        }
            } else {
                setSuccessMessage(false);
                    setErrMessage(true);
                    setMessage("Verification Code is not valid.")
            }
        } catch (error) {
            setErrMessage(true);
            setMessage("Verification Code is not valid.")
        }
    }
    const resendMail = async () => {
        try {
            const response = await resendEmail();
            if (response.status) {
                setErrMessage(false);
                setSuccessMessage(true);
                setMessage("A new code has been sent.");
                //sessionStorage.setItem("statusFlag", 2);
                //window.location.href = '/dashboard';
            }
        } catch (error) {
            console.error(error);
            setErrMessage(true);
            setMessage("Verification Code is not valid.")
        }
    };
   
    const returnToHome = () => {
        sessionStorage.setItem("statusFlag", 2);
        window.location.href = '/dashboard';
    }

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <Modal.Body className='text-center'>
                    <div className="mb-30">
                        <FontAwesomeIcon icon={faEnvelope} className="messageIcon" />
                    </div>
                    <div className="mb-30">
                        <h1 className='fs-40'>Enter PIN</h1>
                        <p className='mt-8 fs-18'>We have sent a confirmation code to your email.</p>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div class="d-flex">
                            <div style={{width:"20%"}}></div>
                            <input type="text" id="code-input" value={code} onChange={(e) => onChangeCode(e.target.value)} style={{width:"50%"}}
                                maxLength={6} placeholder="Enter 6 digit code" name="code" autocomplete="off" class="form-control bg-transparent me-2" />
                            <button type="submit" class="btn btn-primary">Verify</button>
                        </div>
                        <div class="mt-3" style={{textAlign:"start",marginLeft:"20%"}}>
                            {successMessage == true ? <span className="input-success-text ml-1rem" ><small>{message}</small></span> : null}
                            {errMessage == true ? <span className="input-error-text ml-1rem" ><small>{message}</small></span> : null}
                            {errCode == true ? <span className="input-error-text ml-1rem" ><small>Please Enter Code.</small></span> : null}
                        </div>
                    </form>
                    <div className="mb-30">
                        <div className="mt-20">
                            <button className='btn btn-primary' onClick={resendMail}>Resend email</button>
                            <Link onClick={returnToHome} className="link-primary fw-semibold ml-20">
                                <span className="verify-return">Verify Later</span><FontAwesomeIcon icon={faLongArrowAltRight} />
                            </Link>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}
export default VerifyModal;


