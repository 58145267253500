import React, { useEffect, useState } from "react";
import './App.css';
import './assets/plugins.css';
import './assets/style.css';
import './assets/custom.css';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login/Login';
import Dashboard from './components/User/Dashboard';
import Signup from './components/Login/Signup';
import ForgotPassword from './components/Login/ForgotPassword';
import ResetPassword from './components/Login/ResetPassword';
import apiToken from './apiToken';
import Header from "./components/User/Header";
import Footer from "./components/User/Footer";
import List from "./components/User/Presentation/List";
import Configuration from "./components/User/Presentation/Configuration";
import Slides from "./components/User/Presentation/Slides";
import Topics from "./components/User/Presentation/Topics";
import View from "./components/Presentation/View";
import Preview from "./components/Presentation/Preview";
import ChangePassword from "./components/Profile/ChangePassword";
import ViewProfile from "./components/Profile/ViewProfile";
import EditProfile from "./components/Profile/EditProfile";
import OAuth2RedirectHandler from "./components/User/Oauth2/OAuth2RedirectHandler";
import LoginError from "./components/Login/LoginError";
import VerifyEmail from "./components/User/VerifyEmail";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import VerifyLink from "./components/User/VerifyLink";
import Registrations from "./components/User/Presentation/Registrations";


function App() {

  const { token, setToken } = apiToken();
  const [userLoginStatus, setUserLoginStatus] = useState('');
  const [userStatus, setUserStatus] = useState();

  useEffect(() => {
    setUserStatus(sessionStorage.getItem("userStatus"));
    if (token) {
      const jwt = sessionStorage.getItem("jwt");
      if (jwt) {
        const payload = JSON.parse(atob(jwt.split(".")[1]));
        const expiration = payload.exp;
        if (expiration * 1000 < Date.now()) {
          setUserLoginStatus("N");
        } else {
          if(sessionStorage.getItem("userStatus") === "P"){
            setUserLoginStatus("Y");
          }else{
          setUserLoginStatus("Y");
          }
        }
      } else {
        setUserLoginStatus("N");
      }
    } else {
      setUserLoginStatus("N");
    }


  }, []);



  const getStatus = (status) => {
    setUserLoginStatus(status);
    setUserStatus(sessionStorage.getItem("userStatus"));
  }


  return (
    <div className="app">
      {userLoginStatus === 'Y' ?
        <>
          <BrowserRouter>
            <div className="d-flex flex-column flex-root app-root content" id="kt_app_root">
              <div className="app-page  flex-column flex-column-fluid " id="kt_app_page">
                <Header />
                <div className="app-wrapper  flex-column flex-row-fluid  app-container-body" id="kt_app_wrapper">
                  <Routes>
                  <Route path="/oauth2/redirect/:token" element={<OAuth2RedirectHandler token={setToken} status={getStatus}/>} />  
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/login" element={<Navigate to='/dashboard' />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/presentation" element={<List />} />
                    <Route path="/presentation/configuration/:eventCode?" element={<Configuration />} />
                    <Route path="/presentation/slides/:eventCode" element={<Slides />} />
                    <Route path="/presentation/topics/:eventCode" element={<Topics />} />
                    <Route path="/presentation/preview/:eventCode" element={<Preview />} />
                    <Route path="/profile" element={<ViewProfile />} />
                    <Route path="/editProfile" element={<EditProfile />} />
                    <Route path="/changePassword" element={<ChangePassword />} />
                    <Route path="/presentation/view/:eventCode" element={<Navigate to='/presentation' />} />
                    <Route path="/verifylink" element={<VerifyLink />} />
                    <Route path="/presentation/registrations/:eventCode?" element={<Registrations />} />
                  </Routes>
                  </div>
              </div>
            </div>

                  <Footer />
               
          </BrowserRouter>
        </>
        :
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login token={setToken} status={getStatus} />} />
            <Route path="/login" element={<Login token={setToken} status={getStatus} />} />
            <Route path="/signup" element={<GoogleReCaptchaProvider
                    reCaptchaKey={"6Lfq3ZQqAAAAAARDIiVwkrWdn2D3NppY9MGNLooW"}><Signup token={setToken} status={getStatus}/></GoogleReCaptchaProvider>} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/presentation/view/:eventCode" element={<View />} />
            <Route path="/login-error" element={<LoginError />} />
            <Route path="/oauth2/redirect/" element={<Navigate to='/login-error' />} /> 
            <Route path="/oauth2/redirect/:token" element={<OAuth2RedirectHandler token={setToken} status={getStatus}/>} />  
            <Route path="/verifyemail/:code" element={<VerifyEmail />} />
            {userLoginStatus === 'N' ?
              <>
                    <Route path="/presentation" element={<Navigate to='/login' />} /> 
                    <Route path="/presentation/configuration/:eventCode?" element={<Navigate to='/login' />} />
                    <Route path="/presentation/slides/:eventCode" element={<Navigate to='/login' />} />
                    <Route path="/presentation/topics/:eventCode" element={<Navigate to='/login' />} />
                    <Route path="/presentation/preview/:eventCode" element={<Navigate to='/login' />} />
                    <Route path="/profile" element={<Navigate to='/login' />} />
                    <Route path="/editProfile" element={<Navigate to='/login' />} />
                    <Route path="/changePassword" element={<Navigate to='/login' />} />
                    <Route path="/verifylink" element={<Navigate to='/login' />} />
                    <Route path="/presentation/registrations/:eventCode?" element={<Navigate to='/login' />} />
              </>
              :""}
          </Routes>
        </BrowserRouter>
      }
    </div>
  );
}

export default App;
