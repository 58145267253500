import axiosInterceptor from '../helpers/axiosInterceptor';
import { GET_PRESENTATION_LIST, PRESENTATION_CREATE, GET_PRESENTATION_CONFIG, UPDATE_PRESENTATION, GET_SLIDES,
    UPDATE_SLIDES, GET_VIDEO_DETAILS, 
    GET_TOPICS,
    UPDATE_TOPICS,
    DELETE_TOPIC,
    DELETE_PRESENTATION,
    PUBLISH_PRESENTATION,
    SAVE_REGISTER,
    REGISTER_LIST} from "../Constants";

function createPresentation(request) {
    return axiosInterceptor.post(PRESENTATION_CREATE,
        {
            "eventName": request.eventName,
            "pageTitle": request.title,
            "videoSourcePath": request.videoUrl,
            "metaKey": request.metaKey,
            "metaData": request.metaData,
            "showQuestions": request.showQuestions,
            "layoutTypeId": request.layoutTypeId,
            "videoType": request.videoType,
            "allowRegister" : request.allowRegister
          });
}
function getList() {
    return axiosInterceptor.get(GET_PRESENTATION_LIST);
}

function getPresentationDetails(eventCode) {
    return axiosInterceptor.get(GET_PRESENTATION_CONFIG + eventCode);
}

function updatePresentation(request) {
    return axiosInterceptor.post(UPDATE_PRESENTATION,
        {
            "eventName": request.eventName,
            "eventCode": request.eventCode,
            "pageTitle": request.title,
            "videoSourcePath": request.videoUrl,
            "metaKey": request.metaKey,
            "metaData": request.metaData,
            "showQuestions": request.showQuestions,
            "layoutTypeId": request.layoutTypeId,
            "status": request.status,
            "videoType": request.videoType,
            "allowRegister" : request.allowRegister
          });
}

function updateSlides(request) {
    return axiosInterceptor.post(UPDATE_SLIDES,
        request);
}

function getSlideDetails(eventCode) {
    return axiosInterceptor.get(GET_SLIDES + eventCode);
}

function getVideoDetails(eventCode) {
    return axiosInterceptor.get(GET_VIDEO_DETAILS + eventCode);
}

function getTopicDetails(eventCode) {
    return axiosInterceptor.get(GET_TOPICS + eventCode);
}

function updateTopics(request) {
    return axiosInterceptor.post(UPDATE_TOPICS,
        request);
}

function deleteEventTopic(topicId) {
    return axiosInterceptor.post(DELETE_TOPIC + topicId);
}

function deleteEvent(eventId) {
    return axiosInterceptor.post(DELETE_PRESENTATION + eventId);
}

function publishEvent(eventCode) {
    return axiosInterceptor.post(PUBLISH_PRESENTATION + eventCode);
}

export function saveRegister(request) {
    return fetch(SAVE_REGISTER, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(request)
    })
      .then(data => data.json())
}

export function getRegistrationList(eventCode) {
    return axiosInterceptor.get(REGISTER_LIST + eventCode);
}

export {createPresentation, getList, getPresentationDetails, updatePresentation, getSlideDetails, updateSlides, 
        getVideoDetails, getTopicDetails, updateTopics, deleteEventTopic, deleteEvent, publishEvent};