import React, { useState, useEffect } from 'react';
import { getUserDashboard } from "../../services/user";
import { Alert } from 'react-bootstrap';


function Dashboard() {

    const [eventCount, setEventCount] = useState(0);
    const userStatus = sessionStorage.getItem("userStatus");
    const statusFlag = sessionStorage.getItem("statusFlag");
    const verifiedemail = sessionStorage.getItem("verifiedemail");
    const [show, setShow] = useState(false);
    
    const getDetails = async () => {
        try {
            const response = await getUserDashboard();
            setEventCount(response.data.data.events);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        
        if(verifiedemail){
            setShow(true);
            const timer = setTimeout(() => {
                setShow(false); 
              }, 2000);
        }
        if(statusFlag === "0" || statusFlag === "1"){
            if(userStatus === "P"){
                window.location.href = '/verifylink';
            }else{
              getDetails();
            }
        }else{
            getDetails();
        }
       
    }, [])
    return (
        <>
          
                            <div className="d-flex flex-column flex-column-fluid">

                                <div id="kt_app_toolbar" className="app-toolbar  py-3 py-lg-6 ">

                                    <div id="kt_app_toolbar_container" className="app-container  container-xxl d-flex flex-stack ">



                                        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                                            <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                                                Dashboard
                                            </h1>

                                        </div>

                                        {show && (
            <Alert variant="success" onClose={() => setShow(false)} dismissible>
              <span>
              Thanks for verifying your email. 
              </span>
            </Alert>
          )}
                                    </div>
                                </div>

                                <div id="kt_app_content" className="app-content  flex-column-fluid ">


                                    <div id="kt_app_content_container" className="app-container  container-xxl ">
                                        <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
                                            <div className="col-xxl-8">
                                                <div className="row g-5 g-xl-10">
                                                    <div className="col-md-4">
                                                        <div className="card card-flush h-xl-100" style={{ backgroundColor: "#F6E5CA" }}>
                                                            <div className="card-header flex-nowrap pt-5">
                                                                <h3 className="card-title align-items-start flex-column">
                                                                    <span className="d-block fw-bold fs-1 text-gray-800">{eventCount}</span>
                                                                    <span className="mt-1 fw-semibold fs-3">Presentations</span>
                                                                </h3>

                                                            </div>


                                                            <div className="card-body text-center pt-5">

                                                            </div>

                                                        </div>


                                                    </div>

                                                  
                                                </div>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>

          

        </>
    );
}

export default Dashboard;