import React, { useState, useEffect } from 'react';
import "./profile.css"
import { getUserProfile, updateProfile } from '../../services/user';
import { Link } from 'react-router-dom';
import loading from "../../assets/images/loader_grey.gif";
import SweetAlert from "react-bootstrap-sweetalert";
import ToastModal from '../ToastModal';


function EditProfile() {
    const [userDetails, setUserDetails] = useState({ profileImage: "" });
    const [showToast, setShowToast] = useState(false);
    const [alertMsg, setAlertMsg] = useState(false);
    const getUserProfileDetails = () => {
        getUserProfile().then((res) => {
            setUserDetails(res.data.data)
        })
    }

    useEffect(() => {
        getUserProfileDetails()

    }, [])
    const handleChange = (e) => {
        setUserDetails({ ...userDetails, [e.target.name]: e.target.value })
    }
    const handleUserSubmit = async (e) => {
        e.preventDefault();
        try {
            setAlertMsg(true);
            const res = await updateProfile(
                userDetails
            );
            if (res.data.status) {
                setShowToast(true);
                window.scrollTo(0, 0);
                setAlertMsg(false);
                window.setTimeout(function () {
                    setShowToast(false);
                }, 3000);
            }
        } catch (error) {
            console.error(error);
            setAlertMsg(false);
        }

    }


    return (
        <>
            <div className="d-flex flex-column flex-column-fluid">
                <div id="kt_app_toolbar" class=" py-3 py-lg-6 ">
                    <div id="kt_app_toolbar_container" className="app-container  container-xxl d-flex flex-stack ">
                        <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">

                            <h1 class="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                                Profile
                            </h1>

                            <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">

                                <li class="breadcrumb-item text-muted">
                                    <a href="/" class="text-muted text-hover-primary">
                                        Home                            </a>
                                </li>

                                <li class="breadcrumb-item">
                                    <span class="bullet bg-gray-500 w-5px h-2px"></span>
                                </li>

                                <li class="breadcrumb-item text-muted">
                                    Profile                                            </li>
                            </ul>

                        </div>
                    </div>
                    {showToast && <ToastModal show={showToast} message={"Profile Updated Successfully!"} />}
                    <div id="kt_app_content" className="app-content  flex-column-fluid ">
                        <div id="kt_app_content_container" className="app-container  container-xxl ">
                            <div class="card mt-5 mb-80">

                                <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">

                                    <div class="card-title m-0 p-4">
                                        <h3 class="fw-bold m-0">Profile Details</h3>
                                    </div>

                                </div>

                                <div id="kt_account_settings_profile_details" class="collapse show">

                                    {alertMsg ?
                                        <SweetAlert
                                            title=""
                                            timeout={10000}
                                            showConfirm={false}
                                            style={{ width: "200px", height: "100px" }}
                                        > <img src={loading} width="50px" height="50px" />
                                        </SweetAlert>
                                        : <></>}
                                    <form id="kt_account_profile_details_form" class="form" onSubmit={handleUserSubmit}>

                                        <div class="card-body border-top p-9">

                                            <div class="row mb-6">

                                                <label class="col-lg-4 col-form-label required fw-semibold fs-6">Full Name</label>
                                                <div class="col-lg-8">

                                                    <div class="row">

                                                        <div class="col-lg-8 fv-row">
                                                            <input type="text" name="fullname" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Full name" value={userDetails?.fullname} onChange={handleChange} />
                                                        </div>
                                                      
                                                    </div>

                                                </div>

                                            </div>
                                            <div class="row mb-6">
                                                <label class="col-lg-4 col-form-label required fw-semibold fs-6">Email</label>

                                                <div class="col-lg-8 fv-row">
                                                    <input type="text" name="email" class="form-control form-control-lg form-control-solid" placeholder="Email" value={userDetails?.email} disabled />
                                                </div>

                                            </div>
                                            <div class="row mb-6">
                                                <label class="col-lg-4 col-form-label required fw-semibold fs-6">Mobile No</label>

                                                <div class="col-lg-8 fv-row">
                                                    <input type="text" name="mobileno" class="form-control form-control-lg form-control-solid" placeholder="Mobile No" value={userDetails?.mobileno} onChange={handleChange} />
                                                </div>

                                            </div>


                                        </div>

                                        <div class="card-footer d-flex justify-content-end py-6 px-9">
                                            <Link to="/profile"><button type="reset" class="btn btn-light btn-active-light-primary me-2">Discard</button></Link>
                                            <button type="submit" class="btn btn-primary" id="kt_account_profile_details_submit">Save Changes</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditProfile;
