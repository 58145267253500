import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from "axios";
import { API_BASE_URL, GET_PRESENTATION_DETAILS } from '../../Constants.js';
import 'swiper/swiper-bundle.css';
import ViewOne from './ViewOne.js';
import ViewTwo from './ViewTwo.js';
import ViewThree from './ViewThree.js';
import ViewFour from './ViewFour.js';
import { publishEvent } from '../../services/presentation.js';
import ShareLinkModal from '../User/Presentation/ShareLinkModal.js';
import EmbedModal from '../User/Presentation/EmbedModal.js';
import CopyMessageModal from '../User/Presentation/CopyMessageModal.js';

function Preview() {
    const params = useParams();
    const code = params.eventCode;

    const [eventCode, setEventCode] = useState("");
    const [eventName, setEventName] = useState("");
    const [status, setStatus] = useState("");
    const [layoutType, setLayoutType] = useState("");
    const [shareableLink, setShareableLink] = useState('');
    const [showShareable, setShowShareable] = useState(false);
    const [showEmbedModal, setShowEmbedModal] = useState(false);
    const [showCopyModal, setShowCopyModal] = useState(false);
    const [showEmbedCopyModal, setShowEmbedCopyModal] = useState(false);
    const [embedcode, setEmbedcode] = useState('');
    
    useEffect(() => {
        if (code) {
            setEventCode(code);
        }
    });

    useEffect(() => {
        if (code) {
            getDetails(code);
        }
    }, []);

    const eventPublish = async () => {
        try {
          const response = await publishEvent(code);
           if (response.data) {
              getDetails(code);
            }
        
        } catch (error) {
        }
        };

    const getDetails = async (event_code) => {
        try {
            const response = await axios.get(API_BASE_URL + GET_PRESENTATION_DETAILS + event_code);
            if (response.data) {
                setLayoutType(response.data.data.layoutType);
                setEventName(response.data.data.eventName);
                setStatus(response.data.data.status);
            }
        } catch (error) {
        }
    };

    const shareLink = async () => {
        const currentUrl = window.location.origin; // Get current URL
        const link = currentUrl+'/presentation/view/'+code; 

        setShareableLink(link);
        setShowShareable(true); 

    };

    const handleShareModal = () => {
      setShowShareable(false);
    }

    const handleEmbed = () => {
        setShowShareable(false);
        setShowEmbedModal(true);
    }

    const handleModal = () => {
        setShowEmbedModal(false);
    }

    const handleCopy = () => {
        setShowShareable(false);
        setShowCopyModal(true);
        navigator.clipboard.writeText(shareableLink).then(() => {
          console.log('Link copied to clipboard!');
      }).catch(err => {
        console.log('Failed to copy the link!');
      });
    }
    
    const closeCopy = () => {
      setShowCopyModal(false);
    }
  
    const handleEmbedCopy = () => {
        setShowEmbedModal(false);
        setShowEmbedCopyModal(true);
        const code = '<iframe src="'+shareableLink+'" width="600" height="400" frameborder="0" allowfullscreen></iframe>';
        setEmbedcode(code);
        navigator.clipboard.writeText(embedcode).then(() => {
          console.log('Link copied to clipboard!');
      }).catch(err => {
        console.log('Failed to copy the link!');
      });
    }
    
    
    const closeEmbedCopy = () => {
      setShowEmbedCopyModal(false);
    }

    return (
        <>
            <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
                <div className="app-page  flex-column flex-column-fluid " id="kt_app_page">
                    <div className="app-wrapper  flex-column flex-row-fluid " id="kt_app_wrapper">
                        <div className="d-flex flex-column flex-column-fluid">
                            <div id="kt_app_toolbar" className="app-toolbar py-lg-6">
                                <div id="kt_app_toolbar_container" className="app-container container-xxl mt-8">
                                    {status === "D"?
                                    <button className='btn btn-primary' onClick={eventPublish} style={{float:"right"}}>Publish</button>
                                    :
                                    <button className='btn btn-primary' onClick={shareLink} style={{float:"right"}}>Share</button>
                                    }
                                    <h1 className="my-0 event-title">
                                        {eventName}
                                    </h1>
                                </div>
                            </div>
                            <div id="kt_app_content" className="app-content  flex-column-fluid ">
                            <div id="kt_app_content_container" className="app-container  container-xxl ">
                            {layoutType === 'ONE' && <ViewOne eventCode={eventCode} />}
                            {layoutType === 'TWO' && <ViewTwo eventCode={eventCode} />}
                            {layoutType === 'THREE' && <ViewThree eventCode={eventCode} />}
                            {layoutType === 'FOUR' && <ViewFour eventCode={eventCode} />}
                            {showShareable && <ShareLinkModal show={showShareable} shareablelink={shareableLink} onHide={handleShareModal} handleEmbed={handleEmbed} handleCopy={handleCopy}/>}
                            {showEmbedModal && <EmbedModal show={showEmbedModal} shareablelink={shareableLink} onHide={handleModal} handleEmbedCopy={handleEmbedCopy}/>}
                            {showCopyModal && <CopyMessageModal show={showCopyModal} onHide={closeCopy} message={"Link copied to clipboard!"}/>}
                            {showEmbedCopyModal && <CopyMessageModal show={showEmbedCopyModal} onHide={closeEmbedCopy} message={"Link copied to clipboard!"}/>}
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Preview;