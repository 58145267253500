import React from 'react';
import '../Login/Login.css'
import VerifyModal from './VerifyModal';

function VerifyLink() {
    const flag = sessionStorage.getItem("statusFlag");
    return (
        <>
            <div id="kt_app_toolbar" class="container-xxl">
            <div className="row mt-8">
            <div className="col-md-12 text-center">
            <VerifyModal show="true" flag={flag}/>
             </div>
             </div>
            </div>
        </>
    );
}

export default VerifyLink;
